.breadcrumbs {

  &__list {
    @include _row(middle);
    @include _unstyled(ol);
  }

  &__item {
    position: relative;
    @include _row-col(auto);
    padding-right: 1em;

    &:not(:first-child) {
      padding-left: 1.6em;

      &::before {
        content: '';
        @include _absolute(calc(50% - .15em) null null 0);
        @include _arrow(right,.4em,1px);
      }
    }

    a { text-decoration: underline; }
  }
}
