/// 埋め込み要素をレスポンシブ対応させる
///
/// @param {string} $aspect-ratio [16:9]
///   アスペクト比。`1:1`, `4:3`, `16:9`, `21:9` を受け入れる。
///
/// @example scss
///   .element {
///     @include _embed-responsive();
///   }
///
///   // CSS Output
///   .element {
///     position: relative;
///     overflow: hidden;
///   }
///   .element::before {
///     content: '';
///     display: block;
///     width: 100%;
///     padding-top: 56.25%;
///   }
///   .element iframe,
///   .element embed,
///   .element object,
///   .element video {
///     position: absolute;
///     top: 0;
///     left: 0;
///     width: 100%;
///     height: 100%;
///   }

// 1. `padding-top` を使いアスペクト比を持つスペースをコンテナに確保（`padding` の上下%指定は横幅基準で計算される）
//    コンテナ幅が 100% 以外にも指定できるように、コンテナ自身ではなく疑似要素にアスペクト比を指定する
// 2. 親コンテナサイズに連動して埋め込んだ要素のサイズが変更されるように 100% を指定

@mixin _embed-responsive(
  $aspect-ratio: '16:9'
) {
  $height: '';
  @if      $aspect-ratio == '1:1'  { $height: percentage(1/1);  }
  @else if $aspect-ratio == '4:3'  { $height: percentage(3/4);  }
  @else if $aspect-ratio == '16:9' { $height: percentage(9/16); }
  @else if $aspect-ratio == '21:9' { $height: percentage(9/21); }
  @else { @warn "not support"; }

  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: $height;  //1
  }

  iframe,
  embed,
  object,
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;  //2
    height: 100%; //2
  }
}
