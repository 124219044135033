$form-control-border-color: #ccc !default;

.form-control {
  width: 100%;
  outline: 0;
  border: 1px solid $form-control-border-color;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.02);
  transition: all .1s ease-in-out;

  &:focus { border-color: #80bdff; }
  &::placeholder { color: #aaa; }
  &:disabled {
    background-color: #f2f2f2;
    cursor: not-allowed;
  }
}
