@charset "utf-8";

@import 'setting';
@import 'mixin/mixin';

@import 'base/font';
@import 'base/flat-css';
@import 'base/element-styled';
@import 'component/article-list';
@import 'component/breadcrumbs';
@import 'component/btn';
@import 'component/burger-btn';
@import 'component/drawer';
@import 'component/embed';
@import 'component/form-control';
@import 'component/header';
@import 'component/hero';
@import 'component/hidden';
@import 'component/menu';
@import 'component/pager';
@import 'component/pagination';
@import 'component/post-content';

@import 'common';

.hero {
  padding: $groove-size;
  height: _vr(12);
  @include _mq(md){ height: _vr(16); }

  &__media { background-color: $tertiary-color; }
  &__content {
    @include _row(center middle,column);
    padding: 0 _vr(2);
    font-feature-settings: "palt";
  }
  &__txt {
    color: #fff;
    @include _vr-font(26,2);
    font-weight: bolder;
    font-family: $font-stack-yu-gothic;
  }
}

.content {
  margin: 0 $groove-size;
  background: $background-color;
  padding: 0 0 _vr(1);
  @include _mq(sm){ padding: _vr(2.5) _vr(1) _vr(1); }

  &__inner {
    z-index: 1;
    position: relative;
    max-width: _vr(48);
    padding: _vr(1.5) 0 _vr(3);
    background: #fff;

    @include _mq(sm){
      margin: _vr(-5) auto 0;
      padding: _vr(1.5) _vr(1) _vr(3.5);
    }
  }
  &__row {
    @include _row(between,null,wrap);
    @include _mq(md){
      flex-wrap: nowrap;
    }
  }
}

.main {
  @include _row-col();
  @include _vr-font(15);
}
.breadcrumbs {
  margin-bottom: _vr(1.5);
  &__list { @include _vr-font(13); }
}

.post-content {
  font-feature-settings: "palt";
  letter-spacing: .02em;

  h1, h2, h3, h4, h5, h6 { font-family: $font-stack-yu-gothic; }
  h1, h2 {
    @include _vr-font(20);
    padding: _vr(.5) 0;
    border-bottom: 1px solid $tertiary-color;
  }
  h3 { @include _vr-font(18); }
  tr {
    border-top: 1px solid #d8d8d8;
    border-bottom: 1px solid #d8d8d8;
    margin-top: -1px;
  }
  th {
    text-align: left;
    background: $background-color;
  }
  th, td {
    padding: _vr(.5) _vr(.5);
    @include _mq(md){ padding: _vr(.5) _vr(1); }
  }
  blockquote { background: $background-color; }
  strong { font-family: $font-stack-yu-gothic; }

  &__time {
    display: block;
    margin: _vr(-1) 0 _vr(1);
    text-align: right;
    font-family: $font-stack-quicksand;
    @include _vr-font(13);
  }
}
.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.alignright {
  margin-left: 1em;
  margin-bottom: 1em;
  float: right;
}
.alignleft {
  float: left;
  margin-right: 1em;
  margin-bottom: 1em;
}

.form-control { padding: _vr(.25) _vr(.5); }
.form-submit {
  @include _row(middle center);
  &__item { padding: _vr(.125) _vr(.5); }
}
.req { color: red; }
.wpcf7c-conf {
  background: transparent !important;
  border: none !important;
  cursor: default !important;
}
.wpcf7c-btn-confirm,
.wpcf7c-btn-back,
.wpcf7-submit {
  @extend .btn;
}

.article {
  &__heading {
    @include _vr-font(20);
    margin-bottom: _vr(1);
    padding: _vr(.5) 0;
    border-bottom: 1px solid $tertiary-color;
    font-weight: bolder;
    font-family: $font-stack-yu-gothic;
  }
}
.article-list {
  &__item {
    margin: _vr(1) 0;
    padding-bottom: _vr(1);
    border-bottom: 1px solid #d8d8d8;
  }
  &__media {
    @include _row-col(40%);
    transition: all .15s;
  }
  &__content {
    padding-left: _vr(.5);
    font-feature-settings: "palt";
    @include _mq(sm){ padding-left: _vr(1); }
  }
  &__date {
    @include _vr-font(13);
    font-family: $font-stack-quicksand;
  }
  &__ttl {
    text-decoration: underline;
    font-weight: bolder;
    font-family: $font-stack-yu-gothic;
    @include _vr-font(15,.75);
    @include _mq(sm){
      margin: _vr(.5) 0;
      @include _vr-font(16);
    }
    @include _mq(md){
      text-decoration: none;
    }
  }
  &__excerpt { @include _vr-font(13,.75); }

  &__item-inner {
    &:hover {
      .article-list__media {
        @include _mq(md){ opacity: .8; }
      }
      .article-list__ttl { text-decoration: underline; }
    }
  }
}

.pager {
  margin-top: _vr(2.5);

  &__item { @include _vr-font(14,.75); }
  a {
    color: $base-font-color;
    &:hover { text-decoration: underline; }
  }
}
.page-numbers {
  margin: 0 _vr(.25);

  &.current { color: #ccc; }
  &:hover { text-decoration: underline; }
}

.side {
  @include _row-col(1);
  @include _vr-font(14);
  font-feature-settings: "palt";

  @include _mq(md){
    @include _row-col(_vr(9));
    padding-left: _vr(1);
  }

  @include _mq(lg){
    @include _row-col(_vr(12));
    padding-left: _vr(2);
  }
  &__item { margin-bottom: _vr(1); }
}

.side-list {
  @include _unstyled(ul);

  li {
    position: relative;
    padding: _vr(.5) _vr(.75);
    border-bottom: 1px solid #d8d8d8;

    &::before {
      @include _absolute(23px null null 0);
      @include _arrow(right,6px,2px);
      content: '';
    }

    a {
      &:hover { text-decoration: underline; }
    }
  }
}

.pagetop-wrap {
  padding-top: _vr(1.5);
  text-align: center;
}

.pagetop {
  position: relative;
  display: inline-block;
  padding-top: _vr(.375);
  @include _vr-font(14);
  font-family: $font-stack-quicksand;

  &::before {
    content: '';
    display: block;
    @include _absolute(0 null null calc(50% - 8px));
    @include _arrow(up,16px,3px,$base-font-color);
  }
}





