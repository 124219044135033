$entry-spacing-scale: 1 !default;

.post-content {
  $spacing-sm: _vr(.5) * $entry-spacing-scale;
  $spacing-md: _vr(1) * $entry-spacing-scale;
  $spacing-lg: _vr(1.5) * $entry-spacing-scale;
  $spacing-xl: _vr(2) * $entry-spacing-scale;

  text-align: justify;

  h1, h2, h3, h4, h5, h6 {
    font-weight: bolder;
    &:first-child { margin-top: 0; }
  }
  h1,
  h2 { margin: $spacing-xl 0 $spacing-md; }
  h3 { margin: $spacing-lg 0 $spacing-md; }
  h4 { margin: $spacing-lg 0 $spacing-sm; }
  h5,
  h6 { margin: $spacing-md 0 $spacing-sm; }

  p {
    margin: 0 0 $spacing-md;
    &:last-child { margin-bottom: 0; }
  }
  a:not(.btn) {
    color: $primary-color;
    text-decoration: underline;
  }
  hr {
    margin: $spacing-md 0;
    border-width: 1px 0 0 0;
    border-style: solid;
  }
  table {
    @include _unstyled(table);
    width: 100%;
    max-width: 100% !important;
    table-layout: fixed;
    margin-bottom: $spacing-md;
    &:last-child { margin-bottom: 0; }
  }
  th, td {
    vertical-align: top;
  }
  ul, ol {
    padding-left: 1.5em;
    margin: 0 0 $spacing-md;
    &:last-child { margin-bottom: 0; }
    ul, ol { margin-bottom: 0; }
  }
  blockquote {
    margin: $spacing-lg 0;
    padding: $spacing-md;
    &:last-child { margin-bottom: 0; }
  }
}
