.pager {
  @include _row(between);

  &__item {
    @include _row-col(48%);

    &--prev {
      text-align: right;
    }
  }
}
